import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography, Box, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useAdmin } from '../../AdminContext';
import appConfig from '../../utils/appConfig';
import axios from 'axios';

const PaymentPage: React.FC = () => {
  const [responseData, setResponseData] = useState<any>(null);
  const navigate = useNavigate();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [cancelFirstPayment, setCancelFirstPayment] = useState(false);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false); // כדי להציג את הכפתורים הקיימים אחרי לחיצה על "תשלום יתרה"
  const [openCancelRegistrationDialog, setOpenCancelRegistrationDialog] = useState(false); // דיאלוג ביטול רישום
  const [dialogContent, setDialogContent] = useState('');
  const [secondDialogContent, setSecondDialogContent] = useState('');
  const [dialogActions, setDialogActions] = useState<JSX.Element | null>(null);
  const [showDialog, setShowDialog] = useState(false);

  const { isAdmin } = useAdmin();
  const { authorityName } = useParams<{ authorityName: string }>();
  const authority = appConfig.authorities.find(auth => auth.en === authorityName);

  const showPaymentButton = responseData?.field21 !== 0;

  useEffect(() => {
    const storedIdExists = localStorage.getItem('idExists');
    const storedResponseData = localStorage.getItem('responseData');
    
    if (storedIdExists) {
      console.log("מדובר בילד שכבר שולם עבורו ויש לבדוק אם הסכום נכון");
      
      if (storedResponseData) {
        const parsedData = JSON.parse(storedResponseData);
        console.log(parsedData);
        setResponseData(parsedData);
      }
    }
  }, []);

  const handleSubmit = async (paymentMethod: string) => {
    setSelectedPaymentMethod(paymentMethod);
    if (paymentMethod === "hokBankPay") {
      // if (responseData.DirectDebit !== "הוראת קבע בנקאית") {// הסבר: בסופו של דבר הוחלט על ידי חגי לא לתת אפשרות לשלם את ההפרש בהוק אשראי למי שלא שילם את התשלום הראשון באמצעי הזה
      //   navigate(`/${authority?.en}/${paymentMethod}`, { state: { responseData } });
      // } else {
        setDialogContent(`נא לאשר שמסגרת הוראת קבע שונתה לסכום ${responseData.total_amount?`של ${responseData.total_amount} ש"ח`:"לא מוגבל"}`);
        setDialogActions(
          !isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <button 
                onClick={() => handleConfirmUpdate(true, paymentMethod)} 
                style={{ color: 'white' }}
              >
                כן, אני מאשר
              </button>
              <button 
                onClick={() => handleConfirmUpdate(false, paymentMethod)} 
                style={{ backgroundColor: 'red', color: 'white' }}
              >
                חזרה
              </button>
            </div>
          ) : null // You can replace 'null' with a loading indicator if needed
        );
        
        
        setShowDialog(true)
     //}
     // setOpenFirstDialog(true);
    } 
    else if (paymentMethod === "creditcardHok") {// שורה זו עודכנה בהתאם להערה בשורה הבאה
      if (responseData.DirectDebit !== "הוראת קבע פעילה") { 
        navigate(`/${authority?.en}/${paymentMethod}`, { state: { responseData } });
      } else {
        localStorage.setItem('cc_type', "creditcardHok");
        setDialogContent('שימו לב! בלחיצה על אישור יתבצע ביטול של הוראת הקבע הישנה והקמה של הוק חדשה באשראי עם הסכום העדכני');
        setDialogActions(
          !isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <button 
                onClick={() => handleConfirmUpdate(true, paymentMethod)} 
                style={{ color: 'white' }}
              >
                כן, אני מאשר
              </button>
              <button 
                onClick={() => handleConfirmUpdate(false, paymentMethod)} 
                style={{ backgroundColor: 'red', color: 'white' }}
              >
                חזרה
              </button>
            </div>
          ) : null // You can replace 'null' with a loading indicator if needed
        );
        
        
        setShowDialog(true);
      }
    }
     else if (paymentMethod === "creditcardPay"||paymentMethod === "cashOrCheckPay") {
      if(paymentMethod === "creditcardPay"){localStorage.setItem('cc_type', "creditcard");}
      navigate(`/${authority?.en}/${paymentMethod}`, { state: { responseData } });
    }
  
    // Disable the button for 2 seconds
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  
  const handleConfirmUpdate =async (confirm: boolean,paymentMethod:string) => {
    if (confirm) {
        try {
          // הצגת מצב המתנה בזמן המתנה לתגובה מהשרת
          setIsLoading(true); // הצגת טוען

          const response = await axios.post(`${appConfig.apiUrls.baseUrl}/zohoStudents/updateNoteScheduling/${responseData.id}/${paymentMethod}`);
          console.log(response.data)
          // בדיקת תוצאה והצגת הודעה מתאימה
          if (response.status === 200) {
            if(paymentMethod==="hokBankPay"){
              setDialogContent('הוראת הקבע בחשבון הבנק עודכנה בהצלחה');
            }
            if(paymentMethod==="creditcardHok"){
              //setDialogContent('הוראת הקבע בכרטיס האשראי עודכנה בהצלחה');
              navigate(`/${authority?.en}/${paymentMethod}`, { state: { responseData } });
            }
          } else {
              setDialogContent('הייתה בעיה בעדכון הוראת הקבע. נסה שוב מאוחר יותר');
          }
      } catch (error) {
          console.error('Error canceling registration:', error);
          setDialogContent('הייתה בעיה בעדכון הוראת הקבע. נסה שוב מאוחר יותר');
      } finally {
          setIsLoading(false); // סיום מצב הטעינה
      }

      setDialogActions(
        <Button onClick={() => {setShowDialog(false);navigate(`/${authority?.en}`);}} color="success">
          סגור
        </Button>
      );
    } else {
      setShowDialog(false)
    }
  };

  const handleShowPaymentOptions = () => {
    setShowPaymentOptions(true);
  };

  const handleCancelRegistration = () => {
    setOpenCancelRegistrationDialog(true);
  };

  const handleCloseCancelRegistrationDialog = async (confirm: boolean) => {
    if (confirm) {
        try {
            // הצגת מצב המתנה בזמן המתנה לתגובה מהשרת
            setDialogContent('ממתין לאישור מזוהו');
            setIsLoading(true); // הצגת טוען

            const response = await axios.post(`${appConfig.apiUrls.baseUrl}/zohoStudents/updateScheduling/${responseData.id}`);
            // בדיקת תוצאה והצגת הודעה מתאימה
            if (response.status === 200) {
              console.log(response.data);
              setSecondDialogContent('הרישום בוטל בהצלחה. החזר כספי יתבצע בהתאם לנהלים');
            } else {
              setSecondDialogContent('הייתה בעיה בביטול הרישום. נסה שוב מאוחר יותר');
            }
        } catch (error) {
            console.error('Error canceling registration:', error);
            setSecondDialogContent('הייתה בעיה בביטול הרישום. נסה שוב מאוחר יותר');
        } finally {
            setIsLoading(false); // סיום מצב הטעינה
            //setSecondDialogContent("הרישום בוטל בהצלחה. החזר כספי יתבצע בהתאם לנהלים");
            setOpenSecondDialog(true); // פתיחת הדיאלוג החדש עם הודעת אישור 
        }
    } 
    setOpenCancelRegistrationDialog(false); // סגירת הדיאלוג במקרה של ביטול
};

  const handleCloseCancelDialog = (confirm: boolean) => {
    setCancelFirstPayment(confirm);
    setOpenCancelDialog(false);
    navigate(`/${authority?.en}/hokBankPay`, { state: { responseData, cancelFirstPayment: confirm } });
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" align="center" gutterBottom>
        נתוני הרשמה
      </Typography>
     
      {responseData ? (
        <Typography
         variant="h6"
         sx={{
           marginBottom: 2,
           textAlign: 'center',
           padding: 2,
           backgroundColor: '#f0f4f8',
           borderRadius: '8px',
           boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
           border: '1px solid #ddd',
         }}
       >
         <span style={{ fontWeight: 'bold', color: '#333' }}>שם הילד:</span>  {responseData.firstName + " " + responseData.lastName}
         <br />
         <span style={{ fontWeight: 'bold', color: '#333' }}>שם המוסד:</span> {responseData.mosad2}
         <br />
         <span style={{ fontWeight: 'bold', color: '#333' }}>סכום עדכני לתשלום:</span> {responseData.total_amount ? responseData.total_amount : 0}
         <br />
         <span style={{ fontWeight: 'bold', color: '#333' }}>שולם:</span> {responseData.pay_invicoes}
         <br />
         <span style={{ fontWeight: 'bold', color: '#333' }}>הוראת קבע:</span> {responseData.DirectDebit?responseData.DirectDebit:"לא קיימת"}
         <br />
         <span style={{ fontWeight: 'bold', color: '#333' }}>{responseData.field21 >= 0 ? 'יתרה לתשלום:' : 'סכום לזיכוי:'}</span> ₪{Math.abs(responseData.field21)}
       </Typography>
      ) : (
        <Typography variant="body1" align="center">
          אין נתונים להצגה
        </Typography>
      )}

      {!showPaymentOptions ? (
        <Box sx={{ display: 'flex', justifyContent: showPaymentButton ? 'space-around' : 'center', mt: 4, gap: 2 }}>
        {showPaymentButton && responseData?.field21 >= 1 && (
          <button
            color="success"
            onClick={handleShowPaymentOptions}
          >
            תשלום יתרה
          </button>
        )}
        <button
          style={{ backgroundColor: 'red', color: 'white' }}
          onClick={handleCancelRegistration}
        >
          ביטול רישום
        </button>
      </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 4, gap: 2 }}>
          <div className="button-group">
            {responseData?.DirectDebit === "הוראת קבע בנקאית"&& (
              <button
                disabled={isLoading}
                onClick={() => handleSubmit("hokBankPay")}
              >
                {isLoading ? 'טוען...' : 'תשלום ההפרש בהו"ק בנקאית'}
              </button>
            )}
            {(responseData?.pay_hok_cc||responseData?.pay_cc) && 
             (responseData?.DirectDebit=== "הוראת קבע פעילה"||responseData?.DirectDebit=== null) && (
              <button
                disabled={isLoading}
                onClick={() => handleSubmit("creditcardHok")}
              >
                {isLoading ? 'טוען...' : 'תשלום ההפרש בהו"ק אשראי'}
              </button>
            )}
            {responseData?.pay_cc && responseData?.DirectDebit=== null && (
              <button
                disabled={isLoading}
                onClick={() => handleSubmit("creditcardPay")}
              >
                {isLoading ? 'טוען...' : 'תשלום ההפרש באשראי'}
              </button>
            )}
            {isAdmin && (
              <button
                disabled={isLoading}
                onClick={() => handleSubmit("cashOrCheckPay")}
              >
                {isLoading ? 'טוען...' : 'תשלום במזומן/ המחאה'}
              </button>
            )}
          </div>
        </Box>
      )}

      {/* דיאלוג ביטול רישום */}
      <Dialog open={openCancelRegistrationDialog} onClose={() => setOpenCancelRegistrationDialog(false)}>
        <DialogTitle sx={{ textAlign: 'center' }}> שים לב באישור זה הרישום של הילד {responseData?.firstName} {responseData?.lastName} יבוטל ויתקבל זיכוי בהתאם לנהלים. הזיכוי ינתן תוך 14 ימי עסקים</DialogTitle>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <button onClick={() => handleCloseCancelRegistrationDialog(true)} >מאשר</button>
          <button onClick={() => handleCloseCancelRegistrationDialog(false)} style={{ backgroundColor: 'red', color: 'white' }}>חזרה</button>
        </DialogActions>
      </Dialog>

      {/* דיאלוג ביטול תשלום ראשון */}
      <Dialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)}>
        <DialogTitle>שים לב! ביטול תשלום ראשון יביא לשינוי בסכום לתשלום</DialogTitle>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={() => handleCloseCancelDialog(true)} color="error">מאשר</Button>
          <Button onClick={() => handleCloseCancelDialog(false)}>לא</Button>
        </DialogActions>
      </Dialog>

      {/* דיאלוג אישור תשלום */}
      <Dialog open={openSecondDialog} onClose={() => setOpenSecondDialog(false)}>
        <DialogTitle sx={{ textAlign: 'center' }}>{secondDialogContent}</DialogTitle>
        <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={() => {setOpenSecondDialog(false);navigate(`/${authority?.en}`);}}
          >
            סגור
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
    <DialogTitle sx={{ textAlign: 'center' }}>{dialogContent}</DialogTitle>
    <DialogActions sx={{ justifyContent: 'center' }}>
      {dialogActions}
    </DialogActions>
  </Dialog>
    </Box>
  );
}

export default PaymentPage;
